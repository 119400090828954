import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Cookies from "universal-cookie"
import { Helmet } from "react-helmet"

import Layout from "../../components/SnickersNFLComponenets/components/layout"

const IndexPage = () => {
  let ageCookie = new Cookies()
  useEffect(() => {
    if (!ageCookie.get("isOldEnough")) {
      navigate("/snickers-nfl/agegate")
    }
  }, [])

  return (
    <Layout>
      <Helmet>
        <link rel="stylesheet" href="../SnickersNFL/css/app.css" />
        <link rel="stylesheet" href="../SnickersNFL/css/responsive.css" />
      </Helmet>
      <div className="logo-hldr">
        <figure className="m-auto snickers-logo">
          <StaticImage
            src="../../components/SnickersNFLComponenets/images/snickers-3d-logo.png"
            alt="Snickers Logo"
          />
        </figure>
      </div>

      <div className="home-header">
        <span className="knockoutLiteweightRegular text-uppercase">
          Enter for a chance to
        </span>
        <h1 className="text-uppercase">Win tickets to super bowl LVII</h1>
      </div>

      <div className="btn-group-hldr d-md-flex justify-content-between">
        <Link
          to="/snickers-nfl/enter"
          className="btn btn-primary text-uppercase rounded-0"
          role="button"
        >
          Enter
        </Link>
        <Link
          to="/snickers-nfl/prizes"
          className="btn btn-primary text-uppercase rounded-0"
          role="button"
        >
          Prizes
        </Link>
        <Link
          to="/snickers-nfl/official-rules"
          className="btn btn-primary text-uppercase rounded-0"
          role="button"
        >
          Official Rules
        </Link>
      </div>
    </Layout>
  )
}

export default IndexPage
